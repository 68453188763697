import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import { Banner } from "../components/atoms/Banner";
import {
  Hero,
  KeyFigures,
  Features,
  Testimonials,
  References,
  BlogListing,
  Partners,
  Cta,
  KeyPoints,
  Faq,
} from "../components/sections/index";
import { urlFor } from "../utils";

const Specialists = props => {
  const { data } = props;

  const page = (data || {}).specialistsPage;
  const mainPage = (data || {}).mainPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      <Seo
        title="Solution de téléexpertise et de e-RCP - Médecins spécialistes"
        description="La téléexpertise vous permet de mieux trier et prioriser les demandes de prise en charge afin de vous concentrer sur les cas les plus urgents. Utilisez Omnidoc pour centraliser les demandes de vos correspondants sur un outil sécurisé, efficace et valorisé."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie medecins specialistes"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          accentTitle={page.hero.accentTitle}
          description={page.hero.description}
          subtitle="Médecins spécialistes"
          image={page.hero.image}
          buttons={[
            {
              text: "S'inscrire gratuitement",
              href: "https://app.omnidoc.fr/register",
            },
          ]}
        />
      )}
      {page.keyFiguresSection && (
        <KeyFigures
          header={page.keyFiguresSection.title}
          keyFigures={[
            page.keyFiguresSection.keyFigure1,
            page.keyFiguresSection.keyFigure2,
            page.keyFiguresSection.keyFigure3,
          ]}
        />
      )}
      {page.keyPointsSection && (
        <KeyPoints
          title={page.keyPointsSection.title}
          description={page.keyPointsSection.description}
          keyPoints={[
            page.keyPointsSection.keyPoint1,
            page.keyPointsSection.keyPoint2,
            page.keyPointsSection.keyPoint3,
          ]}
        />
      )}
      {page.references && (
        <References
          header={page.references.title}
          references={page.references}
        />
      )}
      {page.simplerCtaBanner && (
        <Banner
          title={page.simplerCtaBanner.title}
          accentTitle={page.simplerCtaBanner.accentTitle}
          buttonText={page.simplerCtaBanner.buttonText}
          url={page.simplerCtaBanner.url}
        />
      )}
      {page.featuresSection && (
        <Features
          title={page.featuresSection.title}
          description={page.featuresSection.description}
          features={page.featuresSection.features}
        />
      )}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      {!!(page.blogListingSection && page.blogListingSection.length) && (
        <BlogListing blog_posts={page.blogListingSection} />
      )}
      {mainPage.partners && (
        <Partners
          title={mainPage.partners.title}
          description={mainPage.partners.description}
          partners={mainPage.partners}
        />
      )}
      {mainPage.faqSection && (
        <Faq
          title={mainPage.faqSection.title}
          faqs={mainPage.faqSection.faqs}
        />
      )}
      {page.ctaBanner && (
        <Cta
          titleLine1={page.ctaBanner.titleLine1}
          titleLine2={page.ctaBanner.titleLine2}
          description={page.ctaBanner.description}
          buttonText={page.ctaBanner.buttonText}
          url={page.ctaBanner.url}
        />
      )}
      <Footer />
    </>
  );
};

export default Specialists;

export const query = graphql`
  query SpecialistsPageQuery {
    specialistsPage: sanitySpecialistsPage(
      _id: { regex: "/(drafts.|)specialistsPage/" }
    ) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      keyPointsSection {
        title
        description
        keyPoint1 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint2 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
        keyPoint3 {
          icon {
            ...Image
            alt
          }
          title
          description
        }
      }
      references {
        title
        logos {
          ...Image
          alt
          url
        }
      }
      simplerCtaBanner {
        title
        accentTitle
        buttonText
        url
      }
      featuresSection {
        title
        description
        features {
          title
          _rawDescription
          image {
            ...Image
            alt
          }
        }
      }
      keyFiguresSection {
        title
        keyFigure1 {
          number
          label
        }
        keyFigure2 {
          number
          label
        }
        keyFigure3 {
          number
          label
        }
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
      ctaBanner {
        titleLine1
        titleLine2
        description
        buttonText
        url
      }
      blogListingSection {
        id
        title
        slug {
          current
        }
        body: _rawBody
        description
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
        mainImage {
          ...ImageWithPreview
          alt
        }
      }
    }
    mainPage: sanityMainPage(_id: { regex: "/(drafts.|)mainPage/" }) {
      partners {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
      faqSection {
        title
        faqs {
          question
          answer
        }
      }
    }
  }
`;
